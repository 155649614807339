import React from 'react';
import Layout from "../components/layout";
import Block from "../components/Block";
import Component from "../components/Component";
const Refunds = () => (
  <Layout pageTitle="Refund Policy">
    <div className="page-content">
      <Block className="">
        <Component>
          <h1>Refund Policy</h1>
          <p>We'll give you a full refund for any reason within 14 days of purchase.</p>
        </Component>
      </Block>
    </div>
  </Layout>
)
export default Refunds;
